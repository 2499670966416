import { defineStore } from 'pinia';
import type { ICatalogCategory } from '@/types';

interface IState {
  data: ICatalogCategory[]
  item: ICatalogCategory | null
}

export const useCategoriesStore = defineStore('categories', {
  state: (): IState => {
    return {
      data: [],
      item: null,
    };
  },
  getters: {},
  actions: {
    async getAll() {
      const { find } = useStrapi();
      const response = await find<ICatalogCategory>('categories', {
        populate: 'deep',
        sort: [
          'order:asc',
        ],
      });

      this.data = response.data as unknown as ICatalogCategory[];
    },
    async getOneBySlug(slug: ICatalogCategory['slug']) {
      const category = this.data.find(c => c.slug === slug);

      if (category) {
        this.item = category;

        return;
      }

      const { find } = useStrapi();
      const response = await find<ICatalogCategory>('categories', {
        populate: 'deep',
        filters: {
          slug,
        },
      });

      if (!response.data.length) {
        this.item = null;

        return;
      }

      this.item = (response.data as unknown as ICatalogCategory[])[0];
    },
  },
});
